import React from 'react'
import './Services.css'
import { useTranslation } from 'react-i18next';

export const Services = () => {
  const { t, i18n } = useTranslation();

  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>{t("services.title")}</h2>
          <p>{t("services.subTitle")}</p>
        </div>

        <div className='row'>
          <div className='col-md-4'>
            <i className='fa fas fa-chart-bar' />
            <div className='service-desc'>
              <h3>{t("services.display.service1.title")}</h3>
              <p>{t("services.display.service1.caption")}</p>
            </div>
          </div>
          <div className='col-md-4'>
            <i className='fa fas fa-pencil-alt' />
            <div className='service-desc'>
              <h3>{t("services.display.service2.title")}</h3>
              <p>{t("services.display.service2.caption")}</p>
            </div>
          </div>
          <div className='col-md-4'>
            <i className='fa fas fa-chart-line' />
            <div className='service-desc'>
              <h3>{t("services.display.service3.title")}</h3>
              <p>{t("services.display.service3.caption")}</p>
            </div>
          </div>
          <div className='col-md-4'>
            <i className='fa fas fa-bed' />
            <div className='service-desc'>
              <h3>{t("services.display.service4.title")}</h3>
              <p>{t("services.display.service4.caption")}</p>
            </div>
          </div>
          <div className='col-md-4'>
            <i className='fa fas fa-hands-helping' />
            <div className='service-desc'>
              <h3>{t("services.display.service5.title")}</h3>
              <p>{t("services.display.service5.caption")}</p>
            </div>
          </div>
          <div className='col-md-4'>
            <i className='fa fas fa-laptop' />
            <div className='service-desc'>
              <h3>{t("services.display.service6.title")}</h3>
              <p>{t("services.display.service6.caption")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
