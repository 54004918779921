import React from 'react'
import './Features.css'
import { useTranslation } from 'react-i18next';

export const Features = () => {
  const { t, i18n } = useTranslation();

  return (
    <div id='features' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>{t("features.title")}</h2>
          <p>{t("features.subTitle")}</p>
        </div>

        <div className='row'>
          <div className="col-xs-6 col-md-3">
            <i className="fa fas fa-microphone" />
            <h3>{t("features.display.feature1.title")}</h3>
            <p>{t("features.display.feature1.caption")}</p>
          </div>
          <div className="col-xs-6 col-md-3">
            <i className="fa fas fa-table" />
            <h3>{t("features.display.feature2.title")}</h3>
            <p>{t("features.display.feature2.caption")}</p>
          </div>
          <div className="col-xs-6 col-md-3">
            <i className="fa fas fa-comment-dots" />
            <h3>{t("features.display.feature3.title")}</h3>
            <p>{t("features.display.feature3.caption")}</p>
          </div>
          <div className="col-xs-6 col-md-3">
            <i className="fa fas fa-chart-line" />
            <h3>{t("features.display.feature4.title")}</h3>
            <p>{t("features.display.feature4.caption")}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
