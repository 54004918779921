import React from 'react'
import './Header.css'
import {Link} from 'react-router-dom'
import { useTranslation } from 'react-i18next';

export const Header = () => {
  const { t, i18n } = useTranslation();

  return (
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8 col-md-offset-2 intro-text'>
                <h1>{t("header.title")}</h1>
                <p className='subT'>{t("header.subTitle")}</p>
                <a href='https://joybandusa.hubspotpagebuilder.com/introduction-to-joysleep' className='signInBtn'>
                      {t("header.login")}
                      {/*link to web app*/}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
