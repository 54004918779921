import React from 'react'
import './About.css'
import { useTranslation } from 'react-i18next';

export const About = () => {
  const { t, i18n } = useTranslation();

  return (
    <div id='about'  >
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-6'>
            <img src="../img/logo.png" className='img-responsive' alt='logo' />
          </div>
          <div className='col-xs-12 col-md-6'>
            <div className='about-text'>
              <h2>{t("about.title")}</h2>
              <p>{t("about.display.info1")}</p>
              <h3>{t("about.subTitle")}</h3> 
              <p className="idea">{t("about.display.info2")}</p>
              <p className="idea">{t("about.display.idea.idea1")}</p>
              <p className="idea">{t("about.display.idea.idea2")}</p>
              <p className="idea">{t("about.display.idea.idea3")}</p>
              <p className="idea">{t("about.display.idea.idea4")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
