import React, {useState} from 'react'
import './Navigation.css'
import { useTranslation } from 'react-i18next';

function  Navigation () {
  
  const { t, i18n } = useTranslation();
  const initialLng = i18n.language;
  const [toggleLng, updateLng] = useState(initialLng);
  
  const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
      updateLng(lng);
  }; 

  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
      
        <div className='navbar-header'>  
        <a className='page-scroll' href='#page-top'>
          </a>{' '}          
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          <a className='navbar-brand page-scroll' href='#page-top'>
          {t('navigation.title')}
          </a>{' '}
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>  
            <li>
              <a href='#about' className='page-scroll'>
              {t('navigation.link.about')}
              </a>
            </li>
            <li>
              <a href='#features' className='page-scroll'>
              {t('navigation.link.features')}
              </a>
            </li>
            <li>
              <a href='#services' className='page-scroll'>
              {t('navigation.link.services')}
              </a>
            </li>      
            <li>
              <a href='#contact' className='page-scroll'>
              {t('navigation.link.contact')}
              </a>
            </li>  
            <li>
              { toggleLng === 'en' ? (
                  <button className='navButton' type="button" onClick={() => changeLanguage('ch')}>
                      {t('navigation.lng.chinese')}
                  </button>
              ) : (
                  <button className='navButton' type="button" onClick={() => changeLanguage('en')}>
                      {t('navigation.lng.english')}
                  </button>
              )}
            </li>     
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navigation;