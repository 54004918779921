import React, { Suspense } from 'react'
import './index.css'
import { About } from './Components/About/About'
import { Contact } from './Components/Contact/Contact'
import { Features } from './Components/Features/Features'
import { Header } from './Components/Header/Header'
import Navigation from './Components/Navigation/Navigation'
import { Services } from './Components/Services/Services'
import { UserMenu } from './Components/UserMenu/UserMenu'

import SmoothScroll from 'smooth-scroll'

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
})

  const LandingPage = () => {
    
  const Loader = () => (
      <div>loading...</div>
  );

  return (
    <div>
      <Suspense fallback={<Loader />}>
        <Navigation />
        <Header data={Header} />
        <About data={About} />
        <Features data={Features} />
        <Services data={Services} />
        {/*<UserMenu data={UserMenu} />*/}
        <Contact data={Contact} />
      </Suspense>

    </div>
  )
}

export default LandingPage

/*
smooth-scroll v12.0.0
Docs & License: https://github.com/cferdinandi/smooth-scroll
(c) 2017 Go Make Things
*/
